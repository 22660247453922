
const dataSchema = {
    required:[],
    dataStructure:{
        id:null,
        calldate: null,
        start_time: null,
        end_time: null
    },
    properties:{
            id: {
                key:"id",
                type: "integer",
                label: "ID"
            },
            calldate: {
                key:"calldate",
                type: "string",
                label: "วันทืั่",
            },
            start_time: {
                key:"start_time",
                type: "string",
                label: "เวลาเริ่มต้นที่สามารถจองได้"
            },
            end_time: {
                key:"end_time",
                type: "string",
                label: "เวลาสิ้นสุดที่สามารถจองได้"
            }
    }
}


export const schema = dataSchema