import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, TimePicker, DatePicker } from "antd";
import { Spin } from "antd";
import { Button } from "antd";
import moment from "moment";
import DataGrid from "../../components/dataGrid";
import { isEmpty } from "../../lib/utils";
import service from "../../services/trips";
import Session, { getItem } from "../../lib/session";
import DateRange from "../../components/dateRange";
import filterFactory from "react-bootstrap-table2-filter";
const { RangePicker } = DatePicker;
const format = "HH:mm";
function dateFormatter(cell, row) {
  if (cell === null) {
    return <span></span>;
  } else {
    return (
      <span>
        {new Date(cell).toLocaleDateString("en-GB") +
          " " +
          new Date(cell).toLocaleTimeString("en-GB")}
      </span>
    );
  }
}

function diffDateFormatter(cell, row) {
  let start = moment(row.time_start);
  let end = moment(row.time_end);
  let diffTime = moment.duration(
    moment(end, "HH:mm:ss a").diff(moment(start, "HH:mm:ss a"))
  );
  if (diffTime.hours() > 0) {
    return (
      <span>{`${diffTime.hours()} ชั่วโมง ${diffTime.minutes()} นาที`}</span>
    );
  } else if (row.time_end === null) {
    return <span>ยังไม่ปิดทริป</span>;
  } else {
    return <span>{`${diffTime.minutes()} นาที`}</span>;
  }
}

const columns = [
  {
    dataField: "id",
    text: "ID",
    sort: true,
    hidden: true,
  },
  {
    dataField: "bus_name",
    text: "หมายเลขรถ",
    sort: true,
  },
  {
    dataField: "driver_name",
    text: "ชื่อ พขร.",
    sort: true,
  },

  {
    dataField: "line_name",
    text: "สายเดินรถ",
    sort: true,
  },
  {
    dataField: "time_start",
    text: "เวลาเปิดทริป",
    formatter: dateFormatter,
  },
  {
    dataField: "time_end",
    text: "เวลาปิดทริป",
    formatter: dateFormatter,
  },
  {
    dataField: "diffDateTime",
    text: "ผลต่างรอบเปิดปิดทริป",
    formatter: diffDateFormatter,
  },
];

class Trips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectlist: [],
      objtmp: [],
      limit: 20,
      offset: 0,
      filter_string: "",
      totalSize: 0,
      sizePerPage: 20,
      page: 1,
      loading: true,
      startdate: "",
      enddate: "",
      starttime: "00:00",
      endtime: "23:59",
    };
  }

  componentDidMount() {
    if (isEmpty(getItem(Session.header))) {
      this.props.history.push("/login");
    } else {
      let tmp_date = new Date();
      let curr_date =
        tmp_date.getFullYear() +
        "-" +
        (tmp_date.getMonth() + 1) +
        "-" +
        tmp_date.getDate();
      this.setState({
        startdate: curr_date,
        enddate: curr_date,
      });
      setTimeout(() => {
        if (this.props.auth.token !== null) {
          this.init_data(this.props.auth.token);
        }
      }, 800);
    }
  }

  init_data = (_token) => {
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string,
    };
    this.spinLoading(true);
    this.init_data_option(this.props.auth.token, obj).then((res, err) => {
      if (res.state) {
        this.setState({
          objectlist: res.data.data.results,
          objtmp: res.data.data.results,
          totalSize: res.data.data.count,
        });
        this.spinLoading(false);
      } else {
        console.log("get data error !!");
      }
    });
  };

  init_data_option = (_token, _option) => {
    return new Promise((resolve, reject) => {
      service
        .getListOption(_token, _option, this.state.reportType)
        .then((res, err) => {
          if (res.status) {
            if (res.data.status === 200) {
              resolve({ state: true, data: res.data });
            }
          } else {
            if (res.error.response.status === 401) {
              this.props.history.push("/login");
            }
          }
        });
    });
  };

  DataGridCallback = (_row, _index) => {};

  PaginationCallback = (type, _limit, _offset, _filter_string, _page) => {
    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string,
      loading: false,
    };

    if (type === "filter" || type === "pagination" || type === "sort") {
      this.spinLoading(true);
      this.init_data_option(this.props.auth.token, obj).then((res, err) => {
        if (res.state) {
          this.setState({
            limit: _limit,
            offset: _offset,
            page: _page,
            objectlist: res.data.data.results,
            objtmp: res.data.data.results,
          });
          this.spinLoading(false);
        } else {
          console.log("get data error !!");
        }
      });
    }
  };

  spinLoading = (_bool) => {
    this.setState({ loading: _bool });
  };

  dataRangeCallback = () => {
    let testStart = moment(
      `${this.state.startdate} ${this.state.starttime}`
    ).format();
    let testEnd = moment(
      `${this.state.enddate} ${this.state.endtime}`
    ).format();
    let objTest = this.state.objtmp;
    let resultsx = objTest.filter(
      (x) =>
        moment(x.time_start).format() >= testStart &&
        moment(x.time_start).format() <= testEnd
    );
    console.log(resultsx);
    this.setState({ objectlist: resultsx });
  };

  onChangeRange = (date, dateString) => {
    this.setState({
      startdate: dateString[0],
      enddate: dateString[1],
    });
  };

  render() {
    const { objectlist, totalSize, page, limit, loading } = this.state;

    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <h2>ประวัติการเดินรถ</h2>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <RangePicker
              placeholder={["วันที่เริ่มต้น", "วันที่สิ้นสุด"]}
              onChange={this.onChangeRange}
              separator="ถึง"
              format="YYYY-MM-DD"
            />
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={4}>
            <TimePicker
              placeholder={"เวลาเริ่มต้น"}
              onChange={(time, timeString) =>
                this.setState({ starttime: timeString })
              }
              format={format}
            />
          </Col>
          <Col span={1}>ถึง</Col>
          <Col span={5}>
            <TimePicker
              placeholder={"เวลาสิ้นสุด"}
              onChange={(time, timeString) =>
                this.setState({ endtime: timeString })
              }
              format={format}
            />
            <Button
              type="primary"
              icon="search"
              onClick={() => this.dataRangeCallback()}
            />
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <Spin spinning={loading} delay={300} tip="Loading...">
              <DataGrid
                callback={this.DataGridCallback}
                totalSize={totalSize}
                page={page}
                limit={limit}
                paginationCallback={this.PaginationCallback}
                columns={columns}
                dataList={objectlist}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

export default connect(mapStateToProps)(Trips);
