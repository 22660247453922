import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, message } from "antd";
import busAction from "../../redux/bus/busTable/busTableRedux";
import DataGrid from "../../components/dataGrid";
import FormModal from "../../components/formModal";
import service from "../../services/bus";
import { schema } from "../../formSchema/bus/busSchema";
import { Button } from "antd";
import { isEmpty } from "../../lib/utils";
import { BaseMediaUrl } from "../../config/configUrl";
import Session, { getItem } from "../../lib/session";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import ModalAlertMessage from "../../components/modalAlertMessage";
import ModalUpdate from "../../formSchema/busRealTime/busRealTimeModal";
import serviceline from "../../services/line";
import "./bus.css";

function imageFormatter(cell, row) {
  return <img src={BaseMediaUrl + cell} className="datagrid-image" />;
}

function nameFormatter(cell, row) {
  return cell == "None" ? <span></span> : cell;
}

// const columns = [
//   {
//     dataField: "image",
//     text: "",
//     formatter: imageFormatter,
//   },
//   {
//     dataField: "bus_number",
//     text: "เบรอ์รถ",
//     sort: true,
//     filter: textFilter({
//       placeholder: "Search from bus number",
//       className: "my-custom-text-filter",
//       delay: 1000,
//     }),
//   },
//   {
//     dataField: "license_plate",
//     text: "ทะเบียนรถ",
//     sort: true,
//     filter: textFilter({
//       placeholder: "Search from license plate",
//       className: "my-custom-text-filter",
//       delay: 1000,
//     }),
//   },
//   {
//     dataField: "driver_name",
//     text: "คนขับ",
//     sort: false,
//     formatter: nameFormatter,
//   },
//   {
//     dataField: "line",
//     text: "สายเดินรถ",
//     sort: false,
//   },
// ];

class BusRealTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectlist: [],
      limit: 10,
      offset: 0,
      filter_string: "",
      totalSize: 0,
      sizePerPage: 10,
      page: 1,
      formAllState: {
        Create: "Create",
        Update: "Update",
        Delete: "Delete",
        Show: "Show",
      },
      columns: [
        {
          dataField: "id",
          text: "ID",
          hidden: true,
        },
        {
          dataField: "image",
          text: "",
          formatter: imageFormatter,
        },
        {
          dataField: "bus_number",
          text: "เบรอ์รถ",
          sort: true,
          filter: textFilter({
            placeholder: "Search from bus number",
            className: "my-custom-text-filter",
            delay: 1000,
          }),
        },
        {
          dataField: "license_plate",
          text: "ทะเบียนรถ",
          sort: true,
          filter: textFilter({
            placeholder: "Search from license plate",
            className: "my-custom-text-filter",
            delay: 1000,
          }),
        },
        {
          dataField: "driver_name",
          text: "คนขับ",
          sort: false,
          formatter: nameFormatter,
        },
        {
          dataField: "line",
          text: "สายเดินรถ",
          sort: false,
        },
        {
          dataField: "status",
          text: "สถานะให้บริการ",
          sort: false,
        },
        {
          dataField: "edit",
          text: "แก้ไขสถานะให้บริการ",
          formatter: this.linkFollow,
        },
      ],
      lines: [],
    };
  }

  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ModalUpdate
        callback={this.funcCallBackModalUpdateBus}
        dataRow={row}
        lines={this.state.lines}
      />
    );
  };

  componentDidMount() {
    if (isEmpty(getItem(Session.header))) {
      this.props.history.push("/login");
    } else {
      setTimeout(() => {
        if (this.props.auth.token !== null) {
          this.init_data(this.props.auth.token);
          this.get_line_data(this.props.auth.token);
        }
      }, 800);
    }
  }

  init_data = (_token) => {
    service.getList(_token).then((res, err) => {
      if (res.data.status === 200) {
        let obj = {
          limit: this.state.limit,
          offset: this.state.offset,
          filter_string: this.state.filter_string,
        };

        this.init_data_option(this.props.auth.token, obj).then((res2, err2) => {
          if (res2.status === 200) {
            this.setState({
              objectlist: res2.data.results,
              totalSize: res.data.data.count,
            });
          } else {
            console.log("get data error !!");
          }
        });
      }
    });
  };

  init_data_option = (_token, _option) => {
    return new Promise((resolve, reject) => {
      service.getListOption(_token, _option).then((res, err) => {
        if (res.status) {
          if (res.data.status === 200) {
            resolve(res.data);
          }
        } else {
          if (res.error.response.status === 401) {
            this.props.history.push("/login");
          }
        }
      });
    });
  };

  get_line_data = (_token) => {
    serviceline.getListLine(_token).then((res, err) => {
      if (res.data != null) {
        if (res.data.status === 200) {
          this.setState({ lines: res.data.data.results });
        }
      }
    });
  };

  funcAdd = (_dataObj) => {
    service.AddBus(this.props.auth.token, _dataObj).then((res, err) => {
      console.log("res => ", res);
      if (res.status) {
        let modalType = "success";
        this.myChildModalMessage.init_modal(
          modalType,
          "เพิ่มข้อมูลรถบัส",
          "ระบบทำการเพิ่มข้อมูล เรียบร้อยแล้วค่ะ"
        );
      } else {
        let modalType = "error";
        this.myChildModalMessage.init_modal(
          modalType,
          "เพิ่มข้อมูลรถบัส",
          "ระบบเกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาตรวจสอบข้อมูล และ ลองใหม่อีกครั้งค่ะ"
        );
      }
    });
  };

  funcFullUpdate = (_dataObj) => {
    service.FullUpdateBus(this.props.auth.token, _dataObj).then((res, err) => {
      console.log("res => ", res);
      if (res.status) {
        message.success("แก้ไขข้อมูลสำเร็จ");
        // console.log("success");
        // let modalType = "success";
        // this.myChildModalMessage.init_modal(
        //   modalType,
        //   "แก้ไขข้อมูลรถบัส",
        //   "ระบบทำการแก้ไขข้อมูล เรียบร้อยแล้วค่ะ"
        // );
      } else {
        console.log("error");
        message.error("เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ");
        // let modalType = "error";
        // this.myChildModalMessage.init_modal(
        //   modalType,
        //   "แก้ไขข้อมูลรถบัส",
        //   "ระบบเกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาตรวจสอบข้อมูล และ ลองใหม่อีกครั้งค่ะ"
        // );
      }
    });
  };

  formatData = (_dataObj, _row) => {
    return new Promise((resolve, reject) => {
      let results = {};
      Object.keys(_dataObj).forEach((row, index) => {
        results[row] = _row[row];

        if (Object.keys(_dataObj).length - 1 === index) {
          resolve(results);
        }
      });
    });
  };

  DataGridCallback = (_row, _index) => {};

  handleClickAddBus = () => {
    let dataForChild = {
      dataObj: {
        results: schema.dataStructure,
        dataDefault: schema,
        schema: schema,
        idx: null,
      },
      formObj: {
        formState: this.state.formAllState.Create,
        formAllState: this.state.formAllState,
      },
    };

    this.myChildFormAddBus.initFormModal(dataForChild);
  };

  funcCallBackModalAddBus = (_dataCallBack) => {
    // console.log(_dataCallBack)
    this.funcAdd(_dataCallBack);
  };

  funcCallBackModalUpdateBus = (_dataCallBack) => {
    delete _dataCallBack.image;
    this.funcFullUpdate(_dataCallBack);
  };

  funcCallbackDelete = (_id) => {
    service.DeleteBus(this.props.auth.token, _id).then((res, err) => {
      // console.log( "res => ", res )
      if (res.status) {
        console.log("success");
        let modalType = "success";
        this.myChildModalMessage.init_modal(
          modalType,
          "ลบข้อมูลรถบัส",
          "ระบบทำการลบข้อมูล เรียบร้อยแล้วค่ะ"
        );
      } else {
        console.log("error");
        let modalType = "error";
        this.myChildModalMessage.init_modal(
          modalType,
          "ลบข้อมูลรถบัส",
          "ระบบเกิดข้อผิดพลาดในการลบข้อมูล ลองใหม่อีกครั้งค่ะ"
        );
      }
    });
  };

  modalMessageCallback = () => {
    // console.log("modalMessageCallback")
    // this.myChildFormAddBus.handleCancel()
    this.myChildFormModal.handleCancel();
  };

  PaginationCallback = (type, _limit, _offset, _filter_string, _page) => {
    console.log("PaginationCallback => on ");
    console.log({ type, _limit, _offset, _filter_string, _page });

    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string,
    };

    if (type === "filter" || type === "pagination" || type === "sort") {
      this.init_data_option(this.props.auth.token, obj).then((res, err) => {
        if (res.status === 200) {
          this.setState({
            limit: _limit,
            offset: _offset,
            page: _page,
            objectlist: res.data.results,
          });
        } else {
          console.log("get data error !!");
        }
      });
    }
  };

  render() {
    let { objectlist, totalSize, page, limit, columns } = this.state;

    return (
      <div>
        <ModalAlertMessage
          callback={this.modalMessageCallback}
          onRef={(refs) => (this.myChildModalMessage = refs)}
        />
        <Row gutter={16}>
          <Col span={24}>
            <h2>สถานะให้บริการ</h2>
          </Col>
        </Row>
        <br />
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <DataGrid
              callback={this.DataGridCallback}
              totalSize={totalSize}
              page={page}
              limit={limit}
              paginationCallback={this.PaginationCallback}
              columns={columns}
              dataList={objectlist}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth, bus: state.bus };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (token) => {
      dispatch(busAction.fetchBusData(token));
    },
    updateBusData: (dataObj) => {
      // console.log("updateBusData => ",dataObj)
      // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
      dispatch(busAction.fetchBusAgain(dataObj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusRealTime);
