import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { Spin, Icon } from "antd";
import { Button, message } from "antd";
import DataGrid from "../../components/dataGrid";
import FormModal from "../../components/formModal";
import { isEmpty } from "../../lib/utils";
import Session, { getItem } from "../../lib/session";
import filterFactory, {
  textFilter,
  dateFilter,
} from "react-bootstrap-table2-filter";
import service from "../../services/callbuskku";
import serviceBus from "../../services/bus";
import { schema } from "../../formSchema/callbus/callbusSchema";
import ModalAdd from "../../formSchema/callbus/callbusModal";
import { BaseMediaUrl } from "../../config/configUrl";
import BootstrapTable from "react-bootstrap-table-next";

function dateFormatter(cell, row) {
  return <span> {new Date(cell).toLocaleDateString("en-GB")}</span>;
}

const statusTypes = {
  padding: "รอยืนยัน",
  approved: "ยืนยันแล้ว",
  coming: "ปล่อยรถ",
  success: "สำเร็จ",
  cancel: "ยกเลิก",
};

function statusFormatter(cell, row) {
  return statusTypes[cell];
}

function busNameFormatter(cell, row) {
  if (cell === "None") return <span>-</span>;
  return <span>{cell}</span>;
}

class CallBus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objectlist: [],
      limit: 20,
      offset: 0,
      filter_string: "",
      totalSize: 0,
      sizePerPage: 20,
      page: 1,
      loading: true,
      visible: false,
      formAllState: {
        Update: "Update",
        Show: "Show",
      },
      busnumber: [],
      columns: [
        {
          dataField: "book_date",
          text: "วันที่เรียกรถ",
          sort: true,
          formatter: dateFormatter,
          filter: dateFilter(),
        },
        {
          dataField: "book_time",
          text: "เวลาที่จองรถ",
          sort: true,
        },
        {
          dataField: "book_user",
          text: "ผู้จอง",
          align: "center",
        },
        {
          dataField: "total_customer",
          text: "จำนวนคน",
          align: "right",
        },
        {
          dataField: "origin_name",
          text: "ต้นทาง",
        },
        {
          dataField: "destination_name",
          text: "ปลายทาง",
        },
        {
          dataField: "bus_name",
          text: "เบอร์รถ",
          formatter: busNameFormatter,
        },
        {
          dataField: "status",
          text: "สถานะ",
          formatter: statusFormatter,
        },
        {
          dataField: "edit",
          text: "แก้ไขสถานะ",
          formatter: this.linkFollow,
        },
        {
          dataField: "direction",
          text: "เส้นทางจุดรับ-ส่ง",
          formatter: this.linkDirection,
        },
      ],
    };
  }

  linkFollow = (cell, row, rowIndex, formatExtraData) => {
    return (
      <ModalAdd
        callback={this.funcCallBackModalUpdate}
        dataRow={row}
        buses={this.state.busnumber}
      />
    );
  };

  linkDirection = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button
        style={{
          backgroundColor: "#1AC5B1",
          borderColor: "#1AC5B1",
          color: "white",
        }}
        onClick={() =>
          window.open(
            `https://www.google.com/maps/dir/?api=1&origin=${row.origin_gps}&destination=${row.destination_gps}&travelmode=car`
          )
        }
      >
        เส้นทาง
      </Button>
    );
  };

  componentDidMount() {
    if (isEmpty(getItem(Session.header))) {
      this.props.history.push("/login");
    } else {
      setTimeout(() => {
        if (this.props.auth.token !== null) {
          this.bus_data(this.props.auth.token);
          this.init_data(this.props.auth.token);
        }
      }, 800);
    }
  }

  init_data = (_token) => {
    let obj = {
      limit: this.state.limit,
      offset: this.state.offset,
      filter_string: this.state.filter_string,
    };
    this.spinLoading(true);
    this.init_data_option(this.props.auth.token, obj).then((res, err) => {
      if (res.state) {
        this.setState({
          objectlist: res.data.data.results,
          totalSize: res.data.data.count,
        });
        this.spinLoading(false);
      } else {
        console.log("get data error !!");
      }
    });
  };

  init_data_option = (_token, _option) => {
    return new Promise((resolve, reject) => {
      service.getListOption(_token, _option).then((res, err) => {
        if (res.status) {
          if (res.data.status === 200) {
            resolve({ state: true, data: res.data });
          }
        } else {
          if (res.error.response.status === 401) {
            this.props.history.push("/login");
          }
        }
      });
    });
  };

  bus_data = (_token) => {
    this.get_bus_data(_token).then((res, err) => {
      if (res.status === 200) {
        this.setState({ busnumber: res.data.results });
      } else {
        console.log("get data error !!");
      }
    });
  };

  get_bus_data = (_token) => {
    return new Promise((resolve, reject) => {
      serviceBus.getList(_token).then((res, err) => {
        if (res.data != null) {
          if (res.data.status === 200) {
            resolve(res.data);
          }
        }
      });
    });
  };

  funcFullUpdate = (_dataObj) => {
    service.FullUpdate(this.props.auth.token, _dataObj).then((res, err) => {
      console.log("res => ", res);
      if (res.status) {
        console.log("success");
        message.success("แก้ไขข้อมูลสำเร็จ");
        this.myChildFormModal.handleCancel();
      } else {
        console.log("error");
        message.error("เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ");
      }
    });
  };

  funcCallBackModalUpdate = (_dataCallBack) => {
    console.log(_dataCallBack);
    this.funcFullUpdate(_dataCallBack);
  };

  modalMessageCallback = () => {
    console.log("modalMessageCallback");
    this.myChildForAdd.handleCancel();
    this.myChildFormModal.handleCancel();
  };

  DataGridCallback = (_row, _index) => {};

  PaginationCallback = (type, _limit, _offset, _filter_string, _page) => {
    let obj = {
      limit: _limit,
      offset: _offset,
      filter_string: _filter_string,
      loading: false,
    };

    if (type === "filter" || type === "pagination" || type === "sort") {
      this.spinLoading(true);
      this.init_data_option(this.props.auth.token, obj).then((res, err) => {
        if (res.state) {
          this.setState({
            limit: _limit,
            offset: _offset,
            page: _page,
            objectlist: res.data.data.results,
          });
          this.spinLoading(false);
        } else {
          console.log("get data error !!");
        }
      });
    }
  };

  spinLoading = (_bool) => {
    this.setState({ loading: _bool });
  };
  formatData = (_dataObj, _row) => {
    return new Promise((resolve, reject) => {
      let results = {};
      Object.keys(_dataObj).forEach((row, index) => {
        results[row] = _row[row];

        if (Object.keys(_dataObj).length - 1 === index) {
          resolve(results);
        }
      });
    });
  };

  render() {
    const { objectlist, totalSize, page, limit, loading, visible, columns } =
      this.state;
    console.log(this.state.busnumber);
    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <h2>ตำแหน่งที่มีการเรียกรถ</h2>
          </Col>
        </Row>
        <br />
        <Row gutter={16}>
          <Col span={24}>
            <Spin spinning={loading} delay={300} tip="Loading...">
              <DataGrid
                callback={this.DataGridCallback}
                totalSize={totalSize}
                page={page}
                limit={limit}
                paginationCallback={this.PaginationCallback}
                columns={columns}
                dataList={objectlist}
              />
            </Spin>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (token) => {
      // dispatch(busAction.fetchBusData(token))
    },
    updateBusData: (dataObj) => {
      // console.log("updateBusData => ",dataObj)
      // console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
      // dispatch(busAction.fetchBusAgain(dataObj))
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallBus);
