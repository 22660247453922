import React from "react";
import { Main } from "../main";
import { Dashboard } from "../containers/dashboard";
import { Signin } from "../containers/signin";
import { Bus } from "../containers/bus";
import { Card } from "../containers/card";
import { Driver } from "../containers/driver";
import { CallBus } from "../containers/callbus";
import { Coin } from "../containers/coin";
import { CoinSave } from "../containers/coinSave";
import { Line } from "../containers/line";
import { BusStop } from "../containers/busStop";
import { ReportTransactionCard } from "../containers/reportTransactionCard";
import { Farecount } from "../containers/farecount";
import { Trips } from "../containers/trips";
import { Fuel } from "../containers/fuel";
import { Card_customers } from "../containers/card_customers";
import { Fares } from "../containers/fares";
import { ReportBusSummary } from "../containers/reportBusSummary";
import { CustomerBusstopCounts } from "../containers/customer_busstop_count";
import { Employees } from "../containers/employees";
import { CardType } from "../containers/cardtype";
import { DailyTransaction } from "../containers/rpt_daily_transaction";
import { TransactionSummaryChart } from "../containers/TransactionSummaryChart";
import { CustomerCNTChart } from "../containers/customer_cnt_chart";
import { RegisterCard } from "../containers/registerCard";
import { ReportCardForSUT } from "../containers/reportCardForSUT";
import { SummaryChart } from "../containers/summary_chart";
import { Annoucement } from "../containers/annoucement";
import { Life } from "../containers/life";
import { Feedback } from "../containers/feedback";
import { LostFeedback } from "../containers/lostFeedback";
import { LineSetting } from "../containers/lineSetting";
import { DashboardSUT } from "../containers/dashboard_sut";
import { SignUp } from "../containers/signup";
import { CardSUTLine } from "../containers/reportCardForSUTwithLine";
import { CountMonthSUT } from "../containers/reportCountMonth";
import { ReportTrips } from "../containers/reporttrip";
import { Ratings } from "../containers/rating";
import { ReportCountPeople } from "../containers/reportCountPeople";
import { CallBusNew } from "../containers/callBusNew";
import { TimeTable } from "../containers/timeTable";
import { ReportCntByTime } from "../containers/reportCntBytime";
import { BusRealTime } from "../containers/busRealTime";
import { KKURating } from "../containers/kkuRating";

const routes = [
  {
    path: "/",
    exact: true,
    name: "main",
    component: Main,
  },
  {
    path: "/dashboard",
    exact: true,
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/master_card",
    exact: true,
    name: "master_card",
    component: Card,
  },
  {
    path: "/master_bus",
    exact: true,
    name: "master_bus",
    component: Bus,
  },
  {
    path: "/master_driver",
    exact: true,
    name: "master_driver",
    component: Driver,
  },
  {
    path: "/call_bus",
    exact: true,
    name: "call_bus",
    component: CallBus,
  },
  {
    path: "/lines",
    exact: true,
    name: "lines",
    component: Line,
  },
  {
    path: "/bus_stop",
    exact: true,
    name: "bus_stop",
    component: BusStop,
  },
  {
    path: "/report_transaction_coin",
    exact: true,
    name: "report_transaction_coin",
    component: Coin,
  },
  {
    path: "/report_transaction_coin_savemoney",
    exact: true,
    name: "report_transaction_coin_savemoney",
    component: CoinSave,
  },
  {
    path: "/rpt_card_transaction",
    exact: true,
    name: "rpt_card_transaction",
    component: ReportTransactionCard,
  },
  {
    path: "/farecount",
    exact: true,
    name: "farecount",
    component: Farecount,
  },
  {
    path: "/trips",
    exact: true,
    name: "trips",
    component: Trips,
  },
  {
    path: "/bus_fuels",
    exact: true,
    name: "bus_fuels",
    component: Fuel,
  },
  {
    path: "/card_customers",
    exact: true,
    name: "card_customers",
    component: Card_customers,
  },
  {
    path: "/fares",
    exact: true,
    name: "fares",
    component: Fares,
  },
  {
    path: "/rpt_bus_summary",
    exact: true,
    name: "rpt_bus_summary",
    component: ReportBusSummary,
  },
  {
    path: "/cust_busstop_cnt",
    exact: true,
    name: "cust_busstop_cnt",
    component: CustomerBusstopCounts,
  },
  {
    path: "/employees",
    exact: true,
    name: "employees",
    component: Employees,
  },
  {
    path: "/cardtype",
    exact: true,
    name: "cardtype",
    component: CardType,
  },
  {
    path: "/rpt_daily_transaction",
    exact: true,
    name: "rpt_daily_transaction",
    component: DailyTransaction,
  },
  {
    path: "/transaction_sum_chart",
    exact: true,
    name: "transaction_sum_chart",
    component: TransactionSummaryChart,
  },
  {
    path: "/cust_cnt_chart",
    exact: true,
    name: "cust_cnt_chart",
    component: CustomerCNTChart,
  },
  {
    path: "/register_card",
    exact: true,
    name: "register_card",
    component: RegisterCard,
  },
  {
    path: "/transaction_card",
    exact: true,
    name: "transaction_card",
    component: ReportCardForSUT,
  },
  {
    path: "/summary_chart",
    exact: true,
    name: "summary_chart",
    component: SummaryChart,
  },
  {
    path: "/annoucment",
    exact: true,
    name: "annoucment",
    component: Annoucement,
  },
  {
    path: "/life",
    exact: true,
    name: "life",
    component: Life,
  },
  {
    path: "/feedback",
    exact: true,
    name: "feedback",
    component: Feedback,
  },
  {
    path: "/lost",
    exact: true,
    name: "lost",
    component: LostFeedback,
  },
  {
    path: "/line_setting",
    exact: true,
    name: "line_setting",
    component: LineSetting,
  },
  {
    path: "/sut",
    exact: true,
    name: "sut",
    component: DashboardSUT,
  },
  {
    path: "/signup",
    exact: true,
    name: "signup",
    component: SignUp,
  },
  {
    path: "/reportwithline",
    exact: true,
    name: "reportwithline",
    component: CardSUTLine,
  },
  {
    path: "/reportcountmonth",
    exact: true,
    name: "reportcountmonth",
    component: CountMonthSUT,
  },
  {
    path: "/reporttrips",
    exact: true,
    name: "reporttrips",
    component: ReportTrips,
  },
  {
    path: "/ratings",
    exact: true,
    name: "ratings",
    component: Ratings,
  },
  {
    path: "/cntpeople",
    exact: true,
    name: "cntpeople",
    component: ReportCountPeople,
  },
  {
    path: "/callbus_new",
    exact: true,
    name: "callbus_new",
    component: CallBusNew,
  },
  {
    path: "/time_setting",
    exact: true,
    name: "time_setting",
    component: TimeTable,
  },
  {
    path: "/reportcnt_time",
    exact: true,
    name: "reportcnt_time",
    component: ReportCntByTime,
  },
  {
    path: "/bus_realtime",
    exact: true,
    name: "bus_realtime",
    component: BusRealTime,
  },
  {
    path: "/kku_rating",
    exact: true,
    name: "kku_rating",
    component: KKURating,
  },
];

export default routes;
