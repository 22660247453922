import React, { Component } from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import { connect } from "react-redux";
import { Card } from "antd";
import { Spin, Alert } from "antd";
import authAction from "../../redux/auth/authRedux";
import Session, { setItem, clear } from "../../lib/session";
import { concat } from "redux";
import Logo from "../../components/logo";

class Signin extends Component {
  constructor(pros) {
    super(pros);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    clear();
  }

  componentDidUpdate(preProps) {
    if (preProps.auth.token !== this.props.auth.token) {
      if (this.props.auth.token !== null) {
        this.funcSetDataSession(this.props.auth).then((res, err) => {
          this.props.history.push("/");
        });
      }
    } else {
      if (preProps.auth.loginError !== this.props.auth.loginError) {
        if (this.props.auth.loginError !== null) {
          this.pageUnLoading();
        }
      }
    }
  }

  funcSetDataSession = (_dataObj) => {
    let sessionObj = {
      user: {
        email: _dataObj.user.email,
        groups: _dataObj.user.groups,
        username: _dataObj.user.username,
        id: _dataObj.user.id,
      },
      token: _dataObj.token,
    };
    return new Promise((resolve, reject) => {
      setItem(Session.header, sessionObj);
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.pageLoading();
        this.props.requestSignin(values.username, values.password);
      }
    });
  };

  pageLoading = () => {
    this.setState({ loading: true });
  };

  pageUnLoading = () => {
    this.setState({ loading: false });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Spin spinning={this.state.loading} delay={300} tip="Loading...">
        {this.props.auth.loginError && (
          <Alert
            message="ลงชื่อเข้าใช้ไม่สำเร็จ"
            description="กรุณาตรวจสอบความถูกต้องของชื่อผู้ใช้และรหัสผ่านให้ตรงกัน"
            type="error"
            showIcon="showIcon"
          />
        )}
        <div
          style={{
            marginTop: 60,
          }}
        >
          <center>
            <Card
              style={{
                width: 360,
              }}
            >
              <Form onSubmit={this.handleSubmit} className="login-form">
                <Form.Item>
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Logo />
                    <h4>SMARTBUS KKU</h4>
                  </div>
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("username", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาใส่ชื่อผู้ใช้",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      placeholder="ชื่อผู้ใช้ /Username"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: "กรุณาใส่รหัสผ่าน!",
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="รหัสผ่าน / Password"
                    />
                  )}
                </Form.Item>
                <Form.Item>
                  <Button
                    block={true}
                    type="primary"
                    htmlType={"submit"}
                    className={"login-form-button"}
                  >
                    เข้าสู่ระบบ
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </center>
        </div>
      </Spin>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(Signin);

const mapStateToProps = (state) => {
  return { auth: state.auth };
};

const mapDispatchToProps = (dispatch) => {
  return {
    requestSignin: (_username, _password) => {
      dispatch(authAction.requestLogin(_username, _password));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WrappedNormalLoginForm);
