const mapKey = {
  key: "HIgF50ihvnOvGRe2z5ScxYid6UDJYerI",
};

const apiKeyDev = {
  key: "RBKuM7jGU1FonC42wfD1g01Nw3Ct4nHU",
};

const apiKeyProd = {
  key: "0Nyp86Zz15urLeQD1nLi26vhwEQ0bk9S",
};

export const Key = apiKeyProd.key;
export const MapKey = mapKey.key;
