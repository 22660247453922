import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, } from 'antd'
import { Spin, Icon } from 'antd'
import { Button, message } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, } from 'react-bootstrap-table2-filter'
import service from '../../services/callBusTimeTable'
import { schema } from '../../formSchema/timeSetting/timesettingSchema'
import ModalAdd from '../../formSchema/timeSetting/timeSettingAdd'
import moment from "moment"

function dateFormatter(cell, row) {

	return (
		<span> {new Date(cell).toLocaleDateString('en-GB')} </span>
	);
}


// const columns = [
// 	{
// 		dataField: 'book_date',
// 		text: 'วันที่',
//         formatter: dateFormatter,
// 		sort: true,
// 	},{
// 		dataField: 'start_time',
// 		text: 'เวลาเริ่มต้นที่สามารถจองได้',
// 	}, {
// 		dataField: 'end_time',
// 		text: 'เวลาสิ้นสุดที่สามารถจองได้',
// 	}
// ];

class TimeTable extends Component {

	constructor(props) {
		super(props)
		this.state = {
			objectlist: [],
			limit: 20,
			offset: 0,
			filter_string: "",
			totalSize: 0,
			sizePerPage: 20,
			page: 1,
			loading: true,
			formAllState: {
				Create: "Create",
				Update: "Update",
				Delete: "Delete",
				Show: "Show",
			},
			columns: [
				{
					dataField: 'id',
					text: 'ID',
					hidden: true,
					sort: true,
				},
				{
					dataField: 'book_date',
					text: 'วันที่',
					formatter: dateFormatter,
					sort: true,
				},{
					dataField: 'start_time',
					text: 'เวลาเริ่มต้นที่สามารถจองได้',
				}, {
					dataField: 'end_time',
					text: 'เวลาสิ้นสุดที่สามารถจองได้',
				}, {
					dataField: "edit",
					text: "แก้ไข",
					formatter: this.linkFollow,
				  },
			]
		}
	}

	linkFollow = (cell, row, rowIndex, formatExtraData) => {
		return (
		  <ModalAdd title="แก้ไข" callback={this.funcCallBackModalUpdate} dataRow={row} />
		);
	  };

	componentDidMount() {
		if (isEmpty(getItem(Session.header))) {
			this.props.history.push('/login')
		} else {
			setTimeout(() => {
				if (this.props.auth.token !== null) {
					this.init_data(this.props.auth.token)
				}
			}, 800)

		}

	}

	init_data = (_token) => {

		let obj = {
			limit: this.state.limit,
			offset: this.state.offset,
			filter_string: this.state.filter_string
		}
		this.spinLoading(true)
		this.init_data_option(this.props.auth.token, obj).then((res, err) => {

			if (res.state) {
				console.log(res.data.data.results)
				this.setState({
					objectlist: res.data.data.results,
					totalSize: res.data.data.count
				})
				this.spinLoading(false)
			} else {
				console.log("get data error !!")
			}

		})

	}


	init_data_option = (_token, _option) => {
		return new Promise((resolve, reject) => {
			service.getListOption(_token, _option).then((res, err) => {

				if (res.status) {
					if (res.data.status === 200) {
						resolve({ state: true, data: res.data })
					}
				} else {
					if (res.error.response.status === 401) {
						this.props.history.push('/login')
					}
				}

			})
		})
	}

	funcAdd = (_dataObj) => {
		console.log(_dataObj)
		service.AddTime(this.props.auth.token, _dataObj).then((res, err) => {
			console.log("res => ", res)
			if (res.status) {
				console.log("success")
				message.success('เพิ่มข้อมูลสำเร็จ');
				// this.myChildForAdd.handleCancel()

			} else {
				console.log("error")
				message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
			}
		})
	}

	funcFullUpdate = (_dataObj) => {
		service.FullUpdateTime(this.props.auth.token, _dataObj).then((res, err) => {
			console.log("res => ", res)
			if (res.status) {
				console.log("success")
				message.success('แก้ไขข้อมูลสำเร็จ');
				this.myChildFormModal.handleCancel()
			} else {
				console.log("error")
				message.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
			}
		})
	}

	funcCallbackDelete = (_id) => {
		// console.log("funcCallbackDelete on id => ",_id)
		service.DeleteTime(this.props.auth.token, _id).then((res, err) => {
			console.log("res => ", res)
			if (res.status) {
				message.success('ลบข้อมูลสำเร็จ');
				this.myChildFormModal.handleCancel()
			} else {
				message.error('เกิดข้อผิดพลาดในการลบข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
			}
		})
	}

	handleClickAdd = () => {

		let dataForChild = {
			dataObj: {
				results: schema.dataStructure,
				dataDefault: schema,
				schema: schema,
				idx: null,
			},
			formObj: {
				formState: this.state.formAllState.Create,
				formAllState: this.state.formAllState,
			},
		}

		this.myChildForAdd.initFormModal(dataForChild)
	}

	funcCallBackModalAdd = (_dataCallBack) => {
		this.funcAdd(_dataCallBack)
	}

	funcCallBackModalUpdate = (_dataCallBack) => {
		console.log(_dataCallBack)
		this.funcFullUpdate(_dataCallBack)
	}

	modalMessageCallback = () => {
		console.log("modalMessageCallback")
		this.myChildForAdd.handleCancel()
		this.myChildFormModal.handleCancel()
	}


	DataGridCallback = (_row, _index) => {

		// this.formatData(schema.dataStructure, _row).then((res, err) => {

		// 	let dataForChild = {
		// 		dataObj: {
		// 			results: res,
		// 			dataDefault: res,
		// 			schema: schema,
		// 			idx: _index,
		// 			canDelete: true,
		// 		},
		// 		formObj: {
		// 			formState: this.state.formAllState.Update,
		// 			formAllState: this.state.formAllState,
		// 		},
		// 	}

		// 	this.myChildFormModal.initFormModal(dataForChild)
		// })

	}

	PaginationCallback = (type, _limit, _offset, _filter_string, _page) => {

		let obj = {
			limit: _limit,
			offset: _offset,
			filter_string: _filter_string,
			loading: false
		}

		if (type === 'filter' || type === 'pagination' || type === 'sort') {
			this.spinLoading(true)
			this.init_data_option(this.props.auth.token, obj).then((res, err) => {
				if (res.state) {
					this.setState({
						limit: _limit,
						offset: _offset,
						page: _page,
						objectlist: res.data.data.results
					})
					this.spinLoading(false)
				} else {
					console.log("get data error !!")
				}

			})
		}

	}

	spinLoading = (_bool) => {
		this.setState({ loading: _bool })
	}
	formatData = (_dataObj, _row) => {

		return new Promise((resolve, reject) => {
			let results = {}
			Object.keys(_dataObj).forEach((row, index) => {

				results[row] = _row[row]

				if ((Object.keys(_dataObj).length - 1) === index) {
					resolve(results)
				}

			});
		})

	}


	render() {

		const { objectlist, totalSize, page, limit, loading, columns } = this.state
		return (

			<div>
				<Row gutter={16}>
					<Col span={24}>
						<h2>ตั้งค่าเวลาจองรถ</h2>
					</Col>
				</Row>
				<br />
				<Row gutter={16}>
					<Col span={24}>
						<ModalAdd title='เพิ่มรายการ'  dataRow={{}}  callback={this.funcCallBackModalAdd} />
					</Col>
				</Row>
				<br />
				<Row gutter={16}>
					<Col span={24}>
						<Spin spinning={loading} delay={300} tip="Loading...">
							<DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
						</Spin>
					</Col>
				</Row>
			</div>

		);
	}
}

const mapStateToProps = state => {
	return { auth: state.auth }
}

const mapDispatchToProps = dispatch => {
	return {
		fetchData: (token) => {
			// dispatch(busAction.fetchBusData(token))
		},
		updateBusData: (dataObj) => {
			// console.log("updateBusData => ",dataObj)
			// console.log("busAction =>",busAction.fetchBusSuccess(dataObj))
			// dispatch(busAction.fetchBusAgain(dataObj))
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeTable);
