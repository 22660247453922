import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col } from 'antd'
import { Spin } from 'antd'
import { Button } from 'antd'
import DataGrid from '../../components/dataGrid'
import { isEmpty } from '../../lib/utils'
import service from '../../services/customer_busstop_counts'
import Session, { getItem } from '../../lib/session'
import filterFactory, { textFilter, dateFilter, selectFilter} from 'react-bootstrap-table2-filter';

function dateFormatter(cell, row) {

    return (
      <span> {  new Date(cell).toLocaleDateString('en-GB') + ' ' + new Date(cell).toLocaleTimeString('en-GB') } </span>
    );
  }

const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
      hidden: true,
    },
    {
      dataField: 'count_datetime',
      text: 'วันที่',
      sort: true,
      filter: dateFilter(),
      formatter: dateFormatter,
    },
    {
      dataField: 'bus',
      text: 'เบอร์รถ',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'bussstop',
      text: 'ป้ายรถ',
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: 'amount',
      text: 'จำนวนคนขึ้น - ลง',
      sort: true,
    }
];

class CustomerBusstopCounts extends Component {

    constructor( props ) {
      super( props )
      this.state = {
        objectlist: [],
        limit: 20,
        offset:0,
        filter_string: "",
        totalSize:0,
        sizePerPage:20,
        page:1,
        formAllState: {
          Create: "Create",
          Update: "Update",
          Delete: "Delete",
          Show: "Show",
        },
        loading: false,
      }
    }
  
    componentDidMount() {
  
      if ( isEmpty( getItem( Session.header ) ) ) {
        this.props.history.push( '/login' )
      }else{
        setTimeout(()=>{
          if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
          }
        },800)
      }
    }
  
    spinLoading = (_bool) => {
      this.setState({loading:_bool})
    }
  
    formatData = ( _dataObj, _row ) => {
      return new Promise( ( resolve, reject ) => {
        let results = {}
        Object.keys( _dataObj ).forEach( ( row, index ) => {
  
          results[ row ] = _row[ row ]
  
          if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
            resolve( results )
          }
  
        } );
      } )
    }
  
    init_data = (_token) => {
      this.spinLoading(true)
  
      let obj = {
        limit: this.state.limit,
        offset: this.state.offset,
        filter_string: this.state.filter_string
      }
  
      this.init_data_option(this.props.auth.token,obj).then((res,err) => {
  
          if(res.status === 200){
            this.setState({
              objectlist: res.data.results,
              totalSize: res.data.count
            })
            this.spinLoading(false)
          }else{
            console.log("get data error !!")
          }
      })
    }
  
    init_data_option = (_token,_option) => {
      return new Promise( ( resolve, reject ) => {
        service.getListOption(_token,_option).then((res,err )=> {
          if (res.status){
            if(res.data.status === 200){
              resolve(res.data)
            }
          }else{
            if(res.error.response.status === 401){
                this.props.history.push( '/login' )
            }
          }
        })
      })
    }
  
  
    DataGridCallback  = ( _row, _index ) => {}
  
  
    PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {
      this.spinLoading(true)
  
      let obj = {
        limit: _limit,
        offset: _offset,
        filter_string: _filter_string
      }
  
      if(type === 'filter' || type === 'pagination' || type === 'sort' ){
          this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
            if(res.status === 200){
              this.setState({
                limit: _limit,
                offset: _offset,
                page: _page,
                objectlist: res.data.results,
                totalSize: res.data.count
              })
              this.spinLoading(false)
            }else{
              console.log("get data error !!")
            }
  
          })
      }
  
    }
  
  
    render() {
  
      let { objectlist , totalSize , page , limit } = this.state
  
      return (
      <Spin spinning={this.state.loading} delay={300} tip="Loading..."> <div>
        <Row gutter={16}>
          <Col span={24}>
            <h2>รายงานสถิติคนขึ้น - ลงรถ</h2>
          </Col>
        </Row>
        <br/>
        
        <Row gutter={16}>
          <Col span={24}>
            <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
          </Col>
        </Row>
      </div>
      </Spin>
      );
    }
  }
  
  const mapStateToProps = state => {
    return { auth: state.auth}
  }
  
  export default connect( mapStateToProps )( CustomerBusstopCounts );