import React, { Component } from "react";
import { Button, Modal, Form, Select, Row, Col, AutoComplete } from "antd";
const { Option } = Select;

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        dataSource,
        onchangeAuto,
        onchangeInput,
        dataLine,
      } = this.props;
      const { getFieldDecorator } = form;
      const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 16 },
        },
      };

      const options1 = dataLine.map((item) => (
        <AutoComplete.Option value={item.name} key={item.id}>
          <span>{item.name}</span>
        </AutoComplete.Option>
      ));

      return (
        <Modal
          width={860}
          visible={visible}
          footer={false}
          title="สถานะให้บริการ"
          onCancel={onCancel}
        >
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            <Form {...formItemLayout}>
              <Form.Item label="สถานะให้บริการ">
                <Select
                  defaultValue={dataSource.status}
                  style={{ width: 300 }}
                  onChange={onchangeAuto}
                >
                  <Option value="reddy">พร้อมใช้งาน</Option>
                  <Option value="not_reddy">ไม่พร้อมใช้งาน</Option>
                  <Option value="on_duty">อยู่ในระหว่างปฏิบัติงาน</Option>
                  <Option value="on_demand">รถจองล่วงหน้า</Option>
                </Select>
              </Form.Item>
              <Form.Item label="สายเดินรถ">
                <AutoComplete
                  defaultValue={dataSource.line}
                  style={{ width: 300 }}
                  dataSource={options1}
                  placeholder="สายเดินรถ"
                  optionLabelProp="value"
                  onChange={(event, options) => {
                    onchangeInput(event, "line", options.key);
                  }}
                />
              </Form.Item>
            </Form>
            <Row gutter={32} style={{ marginTop: 15, marginBottom: 15 }}>
              <Col style={{ textAlign: "right" }} xs={{ span: 16, offset: 4 }}>
                <div style={{ marginRight: 20 }}>
                  <Button
                    style={{ width: 100 }}
                    type="danger"
                    onClick={onCancel}
                  >
                    ยกเลิก
                  </Button>
                  {"  "}
                  <Button
                    style={{ width: 100 }}
                    onClick={onCreate}
                    type="primary"
                  >
                    บันทึก
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
      );
    }
  }
);

class ModalUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      dataInForm: props.dataRow,
    };
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleCreate = () => {
    setTimeout(() => {
      this.props.callback(this.state.dataInForm);
      this.setState({ visible: false });
    }, 1000);
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  onChangeTime = (time, key) => {
    let dataObj = this.state.dataInForm;
    let dateStr = time.toString();
    dataObj[key] = dateStr;
    this.setState({ dataInForm: dataObj });
    console.log(this.state.dataInForm);
  };

  onchangeInput = (_event, _key, _id) => {
    let data = this.state.dataInForm;
    data[_key] = _id;
    this.setState({ dataInForm: data });
    console.log(this.state.dataInForm);
  };

  onchangeAuto = (value) => {
    this.setState({ dataInForm: { ...this.state.dataInForm, status: value } });
    // console.log(this.state.dataInForm)
  };

  render() {
    return (
      <div>
        <Button type="primary" onClick={this.showModal}>
          แก้ไข
        </Button>
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          dataSource={this.state.dataInForm}
          dataLine={this.props.lines}
          onchangeAuto={this.onchangeAuto}
          onchangeInput={this.onchangeInput}
        />
      </div>
    );
  }
}
export default ModalUpdate;
