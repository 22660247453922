import React, { Component } from "react"
import { connect } from 'react-redux'
import { Row, Col, message } from 'antd'
import { Button,Spin } from 'antd'
import DataGrid from '../../components/dataGrid'
import FormModal from '../../components/formModal'
import { isEmpty } from '../../lib/utils'
import Session, { getItem } from '../../lib/session'
import service from '../../services/bus_stop'
import filterFactory, { Comparator, textFilter } from 'react-bootstrap-table2-filter'
import ModalAlertMessage from '../../components/modalAlertMessage'
import { schema } from '../../formSchema/bus_stop/bus_stop'
import action from '../../redux/bus_stop/bus_stopTable/bus_stopTableRedux'



class Busstop extends Component {

      constructor( props ) {
        super( props )
        this.state = {
          objectlist: [],
          limit: 20,
          offset:0,
          filter_string: "",
          totalSize:0,
          sizePerPage:20,
          page:1,
          formAllState: {
            Create: "Create",
            Update: "Update",
            Delete: "Delete",
            Show: "Show",
          },
          loading: false,
          columns: [
            {
              dataField: 'id',
              text: 'ID',
              hidden: true,
            },
            {
              dataField: 'name',
              text: 'ชื่อจุดจอด ',
              sort: true,
              filter: textFilter(),
            }, {
              dataField: 'name_eng',
              text: 'ชื่อจุดจอด (ภาษาอังกฤษ)',
              sort: true,
              filter: textFilter(),
            }, {
              dataField: 'latitude',
              text: 'ละติจูด',
              sort: true,
              filter: textFilter(),
            }, {
              dataField: 'longitude',
              text: 'ลองจิจูด',
              sort: true,
              filter: textFilter(),
            }, {
              dataField: "latlng",
              text: "พิกัด",
              formatter: this.linkFollow,
            }
          ]
        }
      }

      componentDidMount() {
        if ( isEmpty( getItem( Session.header ) ) ) {
          this.props.history.push( '/login' )
        } else {
          setTimeout(()=>{
            if(this.props.auth.token !== null){
              this.init_data(this.props.auth.token)
            }
          },800)

        }

      }

      linkFollow = (cell, row, rowIndex, formatExtraData) => {
        return (
         <Button onClick={()=> {window.open(`https://maps.google.com?q=${row.latitude},${row.longitude}`);}}>พิกัด</Button>
        );
      };

      spinLoading = (_bool) => {
        this.setState({loading:_bool})
      }

        init_data = (_token) => {

          let obj = {
            limit: this.state.limit,
            offset: this.state.offset,
            filter_string: this.state.filter_string
          }
          this.spinLoading(true)
          this.init_data_option(this.props.auth.token,obj).then((res,err) => {

              if(res.state){
                this.setState({
                  objectlist: res.data.data.results,
                  totalSize: res.data.data.count,
                })
                this.spinLoading(false)
              }else{
                console.log("get data error !!")
              }

          })

      }

      init_data_option = (_token,_option, _id) => {
        return new Promise( ( resolve, reject ) => {
          service.getListOption(_token,_option, _id).then((res,err)=> {
            if (res.status){
              if(res.data.status === 200){
                resolve({state:true,data:res.data})
              }
            }else{
              if(res.error.response.status === 401){
                  this.props.history.push( '/login' )
              }
            }
  
          })
        })
      }

     
     
      funcAdd = ( _dataObj ) => {
        service.AddBusStop( this.props.auth.token, _dataObj ).then( ( res, err ) => {
            console.log("res => ",res)
            if(res.status){
              console.log("success")
              message.success('เพิ่มข้อมูลสำเร็จ');
              this.myChildForAdd.handleCancel()
          }else{
              console.log("error")
              message.error('เกิดข้อผิดพลาดในการเพิ่มข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
          }
        } )
      }

      funcFullUpdate = ( _dataObj ) => {
        console.log("_dataObj => ",_dataObj)
        service.FullUpdateBusStop( this.props.auth.token, _dataObj ).then( ( res, err ) => {
            console.log("res => ",res)
            if(res.status){
              console.log("success")
              message.success('แก้ไขข้อมูลสำเร็จ');
              this.myChildFormModal.handleCancel()
          }else{
              console.log("error")
              message.error('เกิดข้อผิดพลาดในการแก้ไขข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
          }
        } )
      }

      funcCallbackDelete = ( _id ) => {
        // console.log("funcCallbackDelete on id => ",_id)
        service.DeleteBusStop( this.props.auth.token, _id ).then( ( res, err ) => {
          console.log( "res => ", res )
          if(res.status){
            console.log("success")
            message.success('ลบข้อมูลสำเร็จ');
            this.myChildFormModal.handleCancel()
        }else{
            console.log("error")
            message.error('เกิดข้อผิดพลาดในการลบข้อมูล กรุณาลองใหม่อีกครั้งค่ะ');
        }
        } )
      }

      formatData = ( _dataObj, _row ) => {

        return new Promise( ( resolve, reject ) => {
          let results = {}
          Object.keys( _dataObj ).forEach( ( row, index ) => {
    
            results[ row ] = _row[ row ]
    
            if ( ( Object.keys( _dataObj ).length - 1 ) === index ) {
              resolve( results )
            }
    
          } );
        } )
    
      }

      DataGridCallback  = ( _row, _index ) => {
        // console.log("DataGridCallback => ",_row)
        // console.log("DataGridCallback index  => ",_index)
        this.formatData( schema.dataStructure, _row ).then( ( res, err ) => {
    
          let dataForChild = {
            dataObj: {
              results: res,
              dataDefault: res,
              schema: schema,
              idx: _index,
            },
            formObj: {
              formState: this.state.formAllState.Update,
              formAllState: this.state.formAllState,
            },
          }
    
          this.myChildFormModal.initFormModal( dataForChild )
        } )
    
      }

      handleClickAdd = () => {

        let dataForChild = {
          dataObj: {
            results: schema.dataStructure,
            dataDefault: schema,
            schema: schema,
            idx: null,
            selectoption: null,
          },
          formObj: {
            formState: this.state.formAllState.Create,
            formAllState: this.state.formAllState,
          },
        }
    
        this.myChildForAdd.initFormModal( dataForChild )
      }

      funcCallBackModalAdd = ( _dataCallBack ) => {
        this.funcAdd( _dataCallBack )
      }

      funcCallBackModalUpdate = (_dataCallBack) => {
        this.funcFullUpdate( _dataCallBack )
      }
    
      modalMessageCallback = () => {
        console.log("modalMessageCallback")
        this.myChildForAdd.handleCancel()
        this.myChildFormModal.handleCancel()
      }

      PaginationCallback  = ( type , _limit, _offset , _filter_string , _page) => {

        let obj = {
          limit: _limit,
          offset: _offset,
          filter_string: _filter_string,
          loading:false
        }
    
        if(type === 'filter' || type === 'pagination' || type === 'sort'  ){
          this.spinLoading(true)
            this.init_data_option(this.props.auth.token,obj).then((res,err)=>{
    
              if(res.state){
                this.setState({
                  limit: _limit,
                  offset: _offset,
                  page: _page,
                  objectlist: res.data.data.results,
                  idx: null,
                  totalSize: res.data.data.count,
                })
                this.spinLoading(false)
              }else{
                console.log("get data error !!")
              }
    
            })
        }
    
      }

      render() {
        const { objectlist , totalSize , page , limit, loading, columns} = this.state
        return (
          <Spin spinning={loading} delay={300} tip="Loading...">
          <div>
            <Row gutter={16}>
              <Col span={24}>
                <h2>ข้อมูลป้ายรถเมล์</h2>
              </Col>
            </Row>
            <br/>
            <Row gutter={16}>
              <Col span={24}>
              <Button onClick={() => {this.handleClickAdd()}} style={{float: "right"}} type="primary">เพิ่มรายการ</Button>
              </Col>
            </Row>
            <br/>
            <Row gutter={16}>
              <Col span={24}>
              {
              (
                objectlist.length === 0 ?
                null
                :
                <div>
                  <FormModal callback={this.funcCallBackModalUpdate} callbackDel={this.funcCallbackDelete} title="แก้ไขจุดจอด" onRef={refs => ( this.myChildFormModal = refs )}/>
                  <FormModal callback={this.funcCallBackModalAdd} title="เพิ่มจุดจอด" onRef={refs => ( this.myChildForAdd = refs )}/>
                </div>
              )
            }
                  <DataGrid callback={this.DataGridCallback} totalSize={totalSize} page={page} limit={limit} paginationCallback={this.PaginationCallback} columns={columns} dataList={objectlist} />
              </Col>
            </Row>
          </div>
          </Spin>
        );
      }
    
}

const mapStateToProps = state => {
  return { auth: state.auth ,test: state.Busstop,}
}

export default connect( mapStateToProps )( Busstop );