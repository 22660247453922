import axios from 'axios'
import { BaseUrl } from '../config/configUrl'
import { Key } from '../config/apikey'

const apiVersion = 'v2/'

var KKUtransaction = {

    getList: (_token, _type) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': ('Token ' + _token),
            'apikey': Key
        }

        return new Promise((resolve, reject) => {
            //axios.get((BaseUrl + apiVersion) + 'sut-transactions/?transaction_type=' + _type + '&limit=1000', { headers: headers }).then((response) => {
            axios.get('https://smartbus.kku.ac.th/api/influxdb/query?db=KSTEV&q=select * from COUNTERCAM  where people_total > 0  order by time desc limit 20', { headers: headers }).then((response) => {
                resolve({ status: true, data: response, })
            }).catch((error) => {
                resolve({ status: false, error: error, })
            });
        })

    },

    getListOption: (_token, _option, _type) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': ('Token ' + _token),
            'apikey': Key
        }
        console.log(_type)
        if (_type == null) {
            return new Promise((resolve, reject) => {
                //axios.get((BaseUrl + apiVersion) + 'sut-transactions/?limit=' + _option.limit + '&offset=' + _option.offset + _option.filter_string, { headers: headers }).then((response) => {
                axios.get('https://smartbus.kku.ac.th/api/influxdb/query?db=KSTEV&q=select * from COUNTERCAM  where people_total > 0  order by time desc limit 20', { headers: headers }).then((response) => {
                    resolve({ status: true, data: response, })
                }).catch((error) => {
                    resolve({ status: false, error: error, })
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                //axios.get((BaseUrl + apiVersion) + 'sut-transactions/?transaction_type=' + _type + '&limit=' + _option.limit + '&offset=' + _option.offset + _option.filter_string, { headers: headers }).then((response) => {
                axios.get('https://smartbus.kku.ac.th/api/influxdb/query?db=KSTEV&q=select * from COUNTERCAM  where people_total > 0  order by time desc limit 20', { headers: headers }).then((response) => {
                    resolve({ status: true, data: response, })
                }).catch((error) => {
                    resolve({ status: false, error: error, })
                });
            })
        }
    }

};

export default KKUtransaction;
